import React from "react";
import "./Sec4.css";
import howitworkimg from "../../../../assets/Section3.png";
import sec4 from "../../../../assets/Sec-4.mp4";

const Index = () =>
{
  const start = (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1246_11838)">
        <path
          d="M23.9372 9.20284C23.7792 8.71687 23.3482 8.37281 22.8402 8.32685L15.9121 7.69787L13.1741 1.28761C12.972 0.816655 12.512 0.512695 12 0.512695C11.4881 0.512695 11.0279 0.816655 10.827 1.28761L8.08902 7.69787L1.15982 8.32685C0.651881 8.37373 0.221759 8.71779 0.0628208 9.20284C-0.0952019 9.68881 0.0507355 10.2218 0.434897 10.5588L5.67198 15.1509L4.12783 21.952C4.01485 22.452 4.20895 22.9691 4.62387 23.2691C4.8469 23.4311 5.10893 23.512 5.37187 23.512C5.59783 23.512 5.82396 23.452 6.02593 23.3311L12 19.7591L17.973 23.3311C18.4112 23.593 18.9622 23.569 19.3762 23.2691C19.7911 22.9691 19.9852 22.452 19.8722 21.952L18.3281 15.1509L23.5652 10.5588C23.9491 10.2218 24.0953 9.68991 23.9372 9.20284Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="clip0_1246_11838">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 -0.00292969)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const Sec4data = [
    {
      id: 1,
      titel: "Easy Product Uploading",
      discripiton:
        "Sellers can easily upload their products with images and descriptions to attract buyers and showcase their offerings.",
    },
    {
      id: 2,
      titel: "Direct Buyer-Seller Chat",
      discripiton:
        "Buyers can directly contact sellers through chat to ask questions, negotiate prices, or place orders instantly.",
    },
    {
      id: 3,
      titel: "Real-Time Notifications",
      discripiton:
        "Both buyers and sellers receive instant notifications when a new message arrives or when a product is updated.",
    },
    {
      id: 4,
      titel: "Secure Transactions",
      discripiton:
        "Our system ensures secure and reliable transactions, keeping both buyers and sellers safe.",
    },
    {
      id: 5,
      titel: "Smart Product Search",
      discripiton:
        "Buyers can search for specific products and get personalized recommendations based on their preferences.",
    },
    {
      id: 6,
      titel: "Seller Rating System",
      discripiton:
        "Trust is key – buyers can rate sellers and leave feedback to build a reliable community.",
    },
    {
      id: 7,
      titel: "User-Friendly Dashboard",
      discripiton:
        "Sellers can manage their products, orders, and chats efficiently in an easy-to-use dashboard.",
    },
    {
      id: 8,
      titel: "Quick Offer Updates",
      discripiton:
        "Products and prices can be updated anytime, allowing sellers to respond quickly to market trends.",
    },
    {
      id: 9,
      titel: "Integrated Payment Options",
      discripiton:
        "Coming soon: Secure and fast payments directly within the app to make the purchasing process even easier.",
    },
  ];

  return (
    <div className="Sec4_main_div" id="Sec4">
      <div className="Sec4_sub_div">
        <h1>TicktagGo: The Ultimate Platform for Sellers and Buyers.</h1>
        <div className="content_div">
          <div className="cotent_text_div">
            {Sec4data.slice(0, 5).map((item, index) =>
            {
              return (
                <div className="contect_innetr_text_idv" key={index}>
                  <span>{start}</span>
                  <div className="text_sec4_inner">
                    <h2>{item.titel}</h2>
                    <p>{item.discripiton}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="content_img">
            <img src={howitworkimg} alt="" />
          </div>
          <div className="cotent_text_div">
            {Sec4data.slice(5).map((item, index) =>
            {
              return (
                <div className="contect_innetr_text_idv" key={index + 4}>
                  <span>{start}</span>
                  <div className="text_sec4_inner">
                    <h2>{item.titel}</h2>
                    <p>{item.discripiton}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;