import React from "react";
import "./Sec2.css";
const Index = () =>
{
  return (
    <div id="Sec2" className="Sec2_main_div">
      <div className="Sec2_sub_div">
        <div className="heading_text">
          <h1>Discover the essential features that make selling easier and more efficient.</h1>
          <p>Our app empowers salespeople to showcase their products effortlessly. With a user-friendly interface, uploading products takes just minutes. Connect with potential buyers and expand your reach like never before.</p>
        </div>
        <div className="ABout_txt_sub">
          <div className="about_us_text">
            <h2>Uploading the Feature Section's Detailed Heading</h2>
            <p>
              In a vibrant city, people gather in bustling markets, exchanging goods and sharing stories. Colorful stalls overflow with fresh produce, artisans showcase their crafts, and laughter fills the air. The aroma of street food invites passersby to savor delicious flavors.
            </p>
            <h4>Upload 	&rarr;</h4>
          </div>
          <div className="about_us_text">
            <h2>Long heading is what you see here in this feature section</h2>
            <p>
              Placeholder text is used for filling design spaces, enhancing aesthetic appeal. A variety of elements are engaged within each part of the composition. The flow of life brings together vibrant experiences that highlight beauty amidst the ordinary.
            </p>
            <h4>chat 	&rarr;</h4>
          </div>
          <div className="about_us_text">
            <h2>Long heading is what you see here in this feature section</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla.
            </p>
            <h4 >Secure 	&rarr;</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
