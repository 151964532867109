import React from "react";
import Navbar from "../../components/UI/Navbar/index";
import Footer from "../../components/UI/Footer/index";
import Section1 from "../../pages/MainPage/Sections/Section1";
import Section2 from "../../pages/MainPage/Sections/Section2";
// import Section3 from "../../pages/MainPage/Sections/Section3";
import Section4 from "../../pages/MainPage/Sections/Section4";
import Section5 from "../../pages/MainPage/Sections/Section5";
import Section6 from "../../pages/MainPage/Sections/Section6";
import Section7 from "../../pages/MainPage/Sections/Section7";
import Newsection from "../../pages/MainPage/Sections/NewSection";
import "./main.css";

const Index = () =>
{
  return (
    <div className="main_div_all">
      <Navbar />
      <Section1 />
      <Section2 />
      {/* <Section3 /> */}
      <Section4 />
      <Newsection />
      {/* <Section5 /> */}
      <Section7 />
      {/* <Section6 /> */}

      <Footer />
    </div>
  );
};

export default Index;
