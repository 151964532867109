import React from "react";
import "./Sec1.css";
import BlurText from "../../../../components/BlurText";

const Index = () =>
{
  const handleAnimationComplete = () =>
  {
    console.log('Animation completed!');
  };
  return (
    <div className="Sec1_main_div" id="Sec1">
      <div className="Sec1_sub_div">
        <div className="DIV"></div>
        <div className="text_Div">

          <BlurText
            text="Connect Buyers and Sellers Seamlessly Today"
            delay={150}
            animateBy="words"
            direction="top"
            onAnimationComplete={handleAnimationComplete}
            className="heading_text_sec1"
          />

          <p className="heading_text_p">
            Experience a groundbreaking method for buying and selling products directly through our innovative app. This unique platform is tailored specifically for small business owners and independent sales professionals in Singapore, providing them with the tools and resources needed to thrive in a competitive market. By using our app, users can engage with potential buyers in real-time, allowing for immediate interactions and transactions. This approach not only enhances the selling process but also fosters relationships between sellers and customers, creating an engaging shopping experience that is both efficient and effective.
          </p>
          <button className="mainbutton">Download Now</button>
        </div>
      </div>
    </div>
  );
};

export default Index;
