import React from "react";
import "./newse.css";

const Index = () =>
{
  return (
    <div className="new_main_div">
      <div className="new_sub_div">
        <h1>Welcome to TicktagGo</h1>

        <div className="ABout_txt_sub">
          <div className="about_us_text">
            <h2>The ultimate marketplace for seamless buying and selling.</h2>
            <p>We understand the challenges of online selling and buying.</p>
            <p>
              Whether you're a seller looking to showcase your products or a buyer
              searching for the best deals, we provide a hassle-free and
              efficient platform tailored to your needs.
            </p>
            <p>
              Our goal is to simplify transactions, enhance communication,
              and ensure a secure marketplace for all users.
            </p>
            <p>
              TicktagGo empowers sellers and buyers to connect effortlessly,
              negotiate, and finalize deals in real-time.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
