import React from "react";
import "./Sec7.css";
import sec7img from "../../../../assets/Sec7.png";

const Index = () =>
{
  const icon = (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="26" height="26" rx="13" fill="#17171A" />
      <rect x="2" y="2" width="22" height="22" rx="11" fill="white" />
      <rect x="2" y="2" width="22" height="22" rx="11" stroke="white" stroke-width="2" />
      <path d="M13 23C7.47715 23 3 18.5228 3 13C3 7.47715 7.47715 3 13 3C18.5228 3 23 7.47715 23 13C23 18.5228 18.5228 23 13 23ZM11.2955 16.2929C11.686 16.6834 12.3192 16.6834 12.7097 16.2929L18.3666 10.636C18.7571 10.2455 18.7571 9.61235 18.3666 9.22183C17.9761 8.8313 17.3429 8.8313 16.9524 9.22182L12.7097 13.4645C12.3192 13.855 11.686 13.855 11.2955 13.4645L9.88134 12.0503C9.49081 11.6597 8.8576 11.6597 8.46707 12.0503C8.07657 12.4408 8.07656 13.074 8.46708 13.4645L11.2955 16.2929Z" fill="#2CBD29" />
    </svg>

  );
  return (
    <div className="Sec5_main_div" id="Sec5">
      <div className="Sec5_sub_div">
        <h1>Why Choose TicktagGo?</h1>
        <div className="sub_Sec5_div">
          <div className="Sec5_img_div">
            <img src={sec7img} alt="" />
          </div>
          <div className="sec5_text_main_div">
            <div className="contect_innetr_text_idv">
              <span>{icon}</span>
              <div className="text_sec4_inner">
                <h2>Seamless Buying & Selling:</h2>
                <p>
                  TicktagGo simplifies the process for sellers to list products and buyers to find exactly what they need. A hassle-free marketplace experience at your fingertips.
                </p>
              </div>
            </div>
            <div className="contect_innetr_text_idv">
              <span>{icon}</span>
              <div className="text_sec4_inner">
                <h2>Instant Chat & Negotiation:</h2>
                <p>
                  Connect instantly with buyers and sellers through direct messaging. Negotiate prices, ask questions, and finalize deals quickly.
                </p>
              </div>
            </div>
            <div className="contect_innetr_text_idv">
              <span>{icon}</span>
              <div className="text_sec4_inner">
                <h2>Secure & Reliable Transactions:</h2>
                <p>
                  With TicktagGo, every transaction is safe. We prioritize security and trust so you can focus on buying and selling with confidence.
                </p>
              </div>
            </div>
            <div className="contect_innetr_text_idv">
              <div className="text_sec4_inner">
                <p>
                  Ready to explore a better way to trade? Download TicktagGo today and enjoy a smooth, efficient, and secure buying and selling experience!
                </p>
              </div>
            </div>
            <button className="mainbutton">Download</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
